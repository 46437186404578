
import React from 'react';
import Container from 'react-bootstrap/Container';
import Pic from '../../assets/img/banner.jpg';

class BigPic extends React.Component {
    render() {
        return <Container className="home-big-pic" id="bigPic">
            <img src={Pic} width="100%"/>
        </Container>;
    }
}

export default BigPic;
