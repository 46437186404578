import React from 'react';
import logo from '../assets/img/logo_text.png';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import {config} from "../config/const";

class Header extends React.Component {
    render() {
        return <Container className="header" id="home">
            <div className="hidden-sm hidden-xs">
                <Navbar>
                    <Navbar.Brand href="#home">
                        <img
                            src={logo}
                            height="30"
                            className="d-inline-block align-top"
                            alt="logo"
                        />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="mr-auto">
                            <Nav.Link href="#home">首页</Nav.Link>
                            <Nav.Link href="#caseEntry">案例展示</Nav.Link>
                            <Nav.Link href="#contact">联系方式</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
            </div>
            <div className="show-sm show-xs">
                <Navbar>
                    <Navbar.Brand href="#home">
                        <img
                            src={logo}
                            height="30"
                            className="d-inline-block align-top"
                            alt="logo"
                        />
                    </Navbar.Brand>
                    <Navbar.Collapse id="navbarToggleExternalContent">
                        <Nav className="mr-auto">
                            <Nav.Link href="#home">小可网络技术工作室</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>

                </Navbar>
            </div>
        </Container>
    }
}

class Footer extends React.Component {
    render() {
        return <Container fluid className="footer">
            <Row className="text-center">
                <p className="fit column">
                    <a href="#home">首页</a>
                    <span>|</span>
                    <a href="#caseEntry">案例</a>
                    <span>|</span>
                    <a href="#contact">联系方式</a>
                </p>
                <p className="fit">
                    Copyright © 2020 萧山小可网络科技工作室 WebSite
                    <br/>
                    <a href="https://beian.miit.gov.cn" target="_blank">{config.beianNo}</a>
                </p>
            </Row>
        </Container>;
    }
}

export {Header, Footer};
