import React from 'react';
import Container from "react-bootstrap/Container";
import Pic from "../assets/img/memory_life.jpeg";
import Pic1 from "../assets/img/memory_life_1.jpeg";
import {config} from "../config/const";
import Row from "react-bootstrap/Row";
import Alert from "react-bootstrap/Alert";
import ListGroup from "react-bootstrap/ListGroup";
import Jumbotron from "react-bootstrap/Jumbotron";
import Card from "react-bootstrap/Card";

class ShowPage extends React.Component {
    render() {
        return (
            <div>
                <Jumbotron fluid>
                    <Container>
                        <h1>记忆中的人生</h1>
                        <p>

                            记住你的人生，这里是你的记忆<br/>
                            Remember your life. Here are your memories
                        </p>
                    </Container>
                </Jumbotron>
                <Container id="bigPic">
                    <Alert variant="info">
                        <Alert.Heading>推荐网站</Alert.Heading>
                    </Alert>
                    <Card>
                        <ListGroup variant="flush">
                            <ListGroup.Item>
                                大自然保护协会    <a href="https://www.tnc.org.cn/">https://www.tnc.org.cn/</a>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                Wikihow（超级万能的百科全书）   <a href="https://zh.wikihow.com/">https://zh.wikihow.com/</a>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                数学也可以这么艺术 <a href="https://www.desmos.com/">https://www.desmos.com/</a>
                            </ListGroup.Item>
                        </ListGroup>
                    </Card>
                </Container>
                <br/>
                <Container id="bigPic">
                    <img src={Pic} width="100%"/>
                    <img src={Pic1} width="100%"/>
                </Container>
                <Container fluid className="footer">
                    <Row className="text-center">
                        <p className="fit">
                            <a href="https://beian.miit.gov.cn" target="_blank">{config.beianNo}</a>
                        </p>
                    </Row>
                </Container>
            </div>
        )
    }
}

//记忆人生页面
class MemoryLifePage extends React.Component {
    render() {
        return <div className="home">
            <div className="hidden-xs hidden-sm">
                <ShowPage/>
            </div>
            <div className="show-xs show-sm">
                <ShowPage/>
            </div>
        </div>
    }
}

export default MemoryLifePage;
