import ImgCmsLeoEp from "../assets/img/case2.jpg";
import ImgEmpty from "../assets/img/case3.jpg";
import ImgSrcBit from "../assets/img/case4.jpg";
// import ImgEmpty from "../assets/img/img_empty_case.png";

//案例清单
//图片选取 16:9 即可

let host = document.location.protocol + "//" + document.location.host + "/"

export const CaseList = [
    // {title:"红旗活动",image:ImgSrcActv,link:"#id",desc:"利用互联网+实现线下转线上，提高运作效率，增加用户粘性"},
    {
        title: "比特币网站", image: ImgSrcBit,
        link: "https://www.bybit-cn.com/zh-CN/", desc: ""
    },
    {
        title: "少儿舞蹈培训学校网站", image: ImgEmpty,
        link: host + "./demo/h5_dance_training", desc: "HTML5响应式少儿舞蹈培训学校网站"
    },
    {
        title: "电商平台网站", image: ImgEmpty,
        link: host + "./demo/h5_e_commerce", desc: "Bootstrap响应式电商平台网站"
    },
    {
        title: "旅游景区网站", image: ImgEmpty,
        link: host + "./demo/h5_scenic_spots", desc: "HTML5响应式旅游景区网站"
    },
    {
        title: "蓝色机械制造公司官网", image: ImgEmpty,
        link: host + "./demo/official_blue_machine_make", desc: "蓝色机械制造公司网站"
    },
    {
        title: "礼品网站", image: ImgCmsLeoEp,
        link: "http://leo_ep.demo.wangshaoqi.top/", desc: ""
    },
    {
        title: "蓝色新材料生产厂家官网", image: ImgEmpty,
        link: "./demo/official_blue_material_product", desc: "蓝色新材料生产厂家官网"
    },
    {
        title: "绿色家居装饰工程公司官网", image: ImgEmpty,
        link: "./demo/official_green_home_decoration", desc: "绿色家居装饰工程公司HTML网站"
    },
    {
        title: "白酒品牌酒业科技公司网站", image: ImgEmpty,
        link: host + "./demo/official_liquor_technology", desc: "白酒品牌酒业科技公司网站"
    },
    {
        title: "智慧导览-长春火车站", image: ImgEmpty,
        link: host + "./demo/wisdom_guide_cchcz", desc: "长春火车站智慧导览页面"
    },
    {
        title: "智慧导览-湘湖", image: ImgEmpty,
        link: host + "./demo/wisdom_guide", desc: "湘湖风景区智慧导览页面"
    },
]


const config = {
    documentTitle: "Small Co-小可网络",
    documentDescribe: "小可网络科技，可开发各种小微网站、商城或定制化系统，PC，第三方小程序，App等应用类型",

    beianHidden: false, //备案隐藏，true:隐藏，false:不隐藏
    beianNo: "浙ICP备15032719号-4",
}

if (config.beianHidden) {
    config.documentTitle = "记忆人生"
    config.documentDescribe = "记忆人生"
}

export {config}