
import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ImgSrc1 from '../../assets/img/home_introduce_1.jpg';
import ImgSrc2 from '../../assets/img/home_introduce_2.jpg';
import ImgSrc3 from '../../assets/img/home_introduce_3.jpg';
import Title from "./Title";

class Introduce extends React.Component {
    render() {
        return <Container className="introduce" id="introduce">
            <Row>
                <Title title="简介" subTitle="Introduce"/>
                <p className="desc-s1">
                    我们是一家从事互联网开发的工作室，可开发各种小微网站、商城或定制化系统，
                    可开发平台包括但不限于：PC，第三方小程序，App等，基于互联网思维及设计创意，完成客户各类需求的网站及系统。
                    目前主要服务客户集中在互联网公司、小微商家、政府单位、广告公司等，目前已成功完成并投入使用的客户项目十余个。
                    在现在及未来将继续竭诚为客户服务，提供最大的技术能力及服务能力。
                </p>
            </Row>
            <Row>
                <Col>
                    <Row>
                        <div className="block-s2">
                            <img className="fit" src={ImgSrc1} alt="PC，移动全适配"/>
                        </div>
                    </Row>
                    <Row>
                        <div className="block-s2">
                            <img className="fit" src={ImgSrc2} alt="Best Service"/>
                        </div>
                    </Row>
                </Col>
                <Col xs={8} md={8} lg={8}>
                    <Row>
                        <div className="block-s2 left-s1">
                            <img className="fit" src={ImgSrc3} alt="Contact Us"/>
                        </div>
                    </Row>
                </Col>
            </Row>
        </Container>;
    }
}

export default Introduce;
