
import React from 'react';
import Row from "react-bootstrap/Row";

class CaseItem extends React.Component {
    render() {
        return <Row>
            <div className="block-s2">
                <div className="desc">
                    <p>{this.props.title}</p>
                    <p>{this.props.desc}</p>
                    <p className="more"><a href={this.props.link} target="_blank">了解更多 ></a></p>
                </div>
                <img className="fit" src={this.props.image} alt={this.props.title}/>
            </div>
        </Row>;
    }
}

export default CaseItem;
