
import React from 'react';
import Container from 'react-bootstrap/Container';
import Pic from '../../assets/img/banner.jpg';

class MobileTips extends React.Component {
    render() {
        return <Container fluid className="mobile-tips">
            <small>访问电脑版网站体验最好效果</small>
        </Container>;
    }
}

export default MobileTips;
