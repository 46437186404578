import React from 'react';
import './assets/css/style-one.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import HomePage from "./view/HomePage";
import {config} from "./config/const";
import MemoryLifePage from "./view/MemoryLifePage";


function App() {
    return (
        <div className="App">
            {!config.beianHidden ? <HomePage/> : <MemoryLifePage/>}
        </div>
    );
}

export default App;

