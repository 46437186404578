import React from 'react';
import {Footer, Header} from "../components/Layout";
import BigPic from "../components/home/BigPic";
import Introduce from "../components/home/Introduce";
import CaseEntry from "../components/home/CaseEntryS1";
import Contact from "../components/home/ContactS1";
import MobileTips from "../components/home/MobileTips";

import {CaseList} from "../config/const";

class HomePage extends React.Component{
    render() {
        return <div className="home">
            <div className="hidden-xs hidden-sm">
                <Header/>
                <BigPic/>
                <Introduce/>
                <CaseEntry list={CaseList}/>
                <Contact/>
                <Footer/>
            </div>
            <div className="show-xs show-sm">
                <MobileTips/>
                <Header/>
                <BigPic/>
                <Introduce/>
                <CaseEntry list={CaseList}/>
                <Contact/>
                <Footer/>
            </div>

        </div>
    }
}

export default HomePage;
