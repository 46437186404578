
import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Title from "./Title";
import Img from "../../assets/img/contact-left.jpg";
import BusinessQrCode from "../../assets/img/business_qrcode.jpg";

class Contact extends React.Component {
    render() {
        return <Container className="contact" id="contact">
            <Row>
                <Title title="联系方式" subTitle="Contact"/>
            </Row>
            <Row>
                <Col className="text-center">
                    <div className="block">
                        <img src={BusinessQrCode} className="bus_wx_qr"/>
                    </div>
                </Col>
            </Row>
        </Container>;
    }
}

export default Contact;
