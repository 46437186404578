import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from "react-bootstrap/Row";
import CaseItem from "../case/CaseItem";
import Title from "./Title";

import {Swiper, SwiperSlide} from 'swiper/react';
import SwiperCore, {Autoplay, EffectCoverflow, Pagination} from 'swiper';
// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/effect-fade/effect-fade.scss';
import 'swiper/components/pagination/pagination.scss';

SwiperCore.use([EffectCoverflow, Pagination, Autoplay]);

class CaseEntry extends React.Component {
    render() {
        return <Container fluid className="case" id="caseEntry">
            <Row>
                <Title title="案例" subTitle="Case"/>
            </Row>
            <div className="hidden-sm hidden-xs">
                <Row>
                    <Swiper
                        effect={'coverflow'}
                        coverflowEffect={{rotate: 40, stretch: 2, depth: 100, modifier: 1, slideShadows: true,}}
                        watchSlidesProgress={true}
                        loop={true}
                        spaceBetween={50}
                        slidesPerView={3}
                        autoplay={{delay: 2000, disableOnInteraction: true,}}
                        onSlideChange={() => {
                            // console.log('slide change')
                        }}
                        onSwiper={(swiper) => {
                            // console.log(swiper)
                        }}>
                        {(this.props.list ? this.props.list : []).map((item, index) =>
                            <SwiperSlide key={index}>
                                <CaseItem title={item.title}
                                          image={item.image}
                                          link={item.link}
                                          desc={item.desc}/>
                            </SwiperSlide>
                        )}
                    </Swiper>
                </Row>
            </div>
            <div className="show-sm show-xs">
                <Row>
                    <Swiper
                        coverflowEffect={{rotate: 40, stretch: 2, depth: 100, modifier: 1, slideShadows: true,}}
                        watchSlidesProgress={true}
                        loop={true}
                        autoplay={{delay: 2000, disableOnInteraction: true,}}
                        onSlideChange={() => {
                            // console.log('slide change')
                        }}
                        onSwiper={(swiper) => {
                            // console.log(swiper)
                        }}>
                        {(this.props.list ? this.props.list : []).map((item, index) =>
                            <SwiperSlide key={index}>
                                <CaseItem title={item.title}
                                          image={item.image}
                                          link={item.link}
                                          desc={item.desc}/>
                            </SwiperSlide>
                        )}
                    </Swiper>
                </Row>
            </div>
        </Container>;
    }
}

export default CaseEntry;
