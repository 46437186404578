
import React from 'react';

class Title extends React.Component {
    render() {
        return <div className="title-s1">
            <p>{this.props.title}<span>{this.props.subTitle}</span></p>
        </div>;
    }
}

export default Title;
